import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  ResponsiveContainer,
  BarChart as Chart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Rectangle
} from 'recharts'

import ChartTooltip from 'src/UIComponents/Charts/ChartTooltip'

import { lightGray } from 'src/Styles/settings/Constants'
import { BarChartLoader } from './BarChartLoader'

const RectangleBar = ({ layout, ...props }) => {
  const radius = layout === 'vertical' ? [0, 5, 5, 0] : [5, 5, 0, 0]
  return <Rectangle radius={radius} {...props} stroke='none' />
}

RectangleBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  layout: PropTypes.string
}

const BarChart = memo(({ data, width, height, color, layout, maxValue, itemsType, isLoading }) => {
  const isHorizontal = layout === 'horizontal'

  if (!isHorizontal) {
    height = data.length * 45
  }

  if (isLoading) {
    return (
      <BarChartLoader />
    )
  }

  return (
    <ResponsiveContainer
      width={width}
      height={height}
    >
      <Chart
        layout={layout}
        margin={{ top: 5, right: 0, bottom: 5, left: 0 }}
        data={data}
      >
        {isHorizontal
          ? <XAxis
            interval='preserveStartEnd'
            hide={!isHorizontal}
            type={!isHorizontal ? 'number' : undefined}
            dataKey={isHorizontal ? 'name' : undefined}
            tickLine={false}
            axisLine={false}
          />
          : <YAxis
            type='category'
            tickLine={false}
            axisLine={false}
            dataKey='name'
            dx={5}
            domain={isHorizontal ? [0, 'dataMax'] : undefined}
          />
        }
        <Tooltip cursor={{ fill: 'transparent' }} content={<ChartTooltip data={data} itemsType={itemsType} />} />
        {isHorizontal && <ReferenceLine y={maxValue} stroke={lightGray} strokeDasharray='3 3' />}
        {isHorizontal && <ReferenceLine y={0} stroke={lightGray} strokeDasharray='3 3' />}
        <Bar dataKey='value1' x={5} fill={color} shape={<RectangleBar layout={layout} />} />
      </Chart>
    </ResponsiveContainer>
  )
})

BarChart.defaultProps = {
  layout: 'vertical'
}

BarChart.propTypes = {
  data: PropTypes.array,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  layout: PropTypes.string,
  maxValue: PropTypes.number,
  itemsType: PropTypes.string
}

export default BarChart
