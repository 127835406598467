import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Label from 'src/UIComponents/Label/Label'
import { toMoney, getTextPlural } from 'src/Utils/Utils'

import { darkerGray, white, gray60Percent, smallText, smallerText, smallPadding } from 'src/Styles/settings/Constants'

const ChartTooltip = ({ active, payload, itemsType }) => {
  if (active && payload && payload.length > 0) {
    const values = payload[0].payload
    return (
      <TooltipContainer>
        {values.name && !values.interval && <Label bold fontSize={smallText} color={white}>{values.name}</Label>}
        {values.interval && <Label fontSize={smallerText} textAlign='center' color={gray60Percent}>{values.interval}</Label>}
        {<Label bold fontSize={smallText} color={white}>{toMoney(values.value1 || 0)}</Label>}
        {<Label fontSize={smallerText} textAlign='center' color={white}>{`${values.value2 || 0} ${getTextPlural(itemsType, values.value2)}`}</Label>}
      </TooltipContainer>
    )
  }

  return null
}

ChartTooltip.defaultProps = {
  itemsType: 'transaction'
}

ChartTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  itemsType: PropTypes.string
}

export default ChartTooltip

const TooltipContainer = styled.div`
  background-color: ${darkerGray};
  padding: ${smallPadding};
  border-radius: 5px;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  position: relative;
`
