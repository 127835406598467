import React from 'react'
import ContentLoader from 'react-content-loader'

export const PieChartLoader = () => (
  <ContentLoader
    speed={4}
    width={360}
    height={320}
    viewBox='0 0 360 320'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    style={{ width: '100%' }}
  >
    <rect x='45' y='288' rx='5' ry='5' width='120' height='14' />
    <circle cx='185' cy='147' r='80' />
    <rect x='45' y='262' rx='5' ry='5' width='120' height='14' />
    <rect x='205' y='262' rx='5' ry='5' width='120' height='14' />
    <rect x='205' y='288' rx='5' ry='5' width='120' height='14' />
  </ContentLoader>
)
