import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ResponsiveContainer, PieChart as Chart, Pie, Cell, Legend, Tooltip } from 'recharts'
import ChartTooltip from 'src/UIComponents/Charts/ChartTooltip'

import { smallText, smallMargin, bigMargin, chartColors } from 'src/Styles/settings/Constants'
import { PieChartLoader } from './PieChartLoader'

const renderCustomizedLegend = ({ payload }) => {
  return (
    <CustomLegend>
      {payload.map((entry, index) => (
        <CustomLegendItem color={entry.color} key={`item-${index}`}>
          {entry.value}
        </CustomLegendItem>
      ))}
    </CustomLegend>
  )
}

renderCustomizedLegend.propTypes = {
  payload: PropTypes.any
}

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const RADIAN = Math.PI / 180
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <CustomLabel x={x} y={y} fill='white' textAnchor='middle' dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </CustomLabel>
  )
}

renderCustomizedLabel.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  midAngle: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  percent: PropTypes.number,
  index: PropTypes.number
}

// eslint-disable-next-line no-unused-vars
const PieChart = memo(({ data, width, height, color, itemsType, isLoading, showLabel }) => {
  const COLORS = chartColors
  const dataKey = 'value1'

  if (isLoading) {
    return <PieChartLoader />
  }

  return (
    <ResponsiveContainer width={width} height={height}>
      <Chart>
        <Legend content={renderCustomizedLegend} align='center' verticalAlign='bottom' />
        <Tooltip cursor={{ strokeDasharray: '8,5' }} content={<ChartTooltip data={data} itemsType={itemsType} />} />
        <Pie
          labelLine={false}
          label={showLabel && renderCustomizedLabel}
          legendType='circle'
          data={data}
          nameKey='name'
          cx='50%'
          cy='50%'
          innerRadius={0}
          outerRadius={80}
          dataKey={dataKey}
        >
          {data &&
            data
              .sort((a, b) => b[dataKey] - a[dataKey])
              .map((entry, index) => <Cell key={`cell-${index}`} stroke={COLORS[index % COLORS.length]} fill={COLORS[index % COLORS.length]} />)}
        </Pie>
      </Chart>
    </ResponsiveContainer>
  )
})

PieChart.defaultProps = {
  showLabel: false
}

PieChart.propTypes = {
  data: PropTypes.array,
  width: PropTypes.string,
  height: PropTypes.number,
  color: PropTypes.string,
  itemsType: PropTypes.string
}

export default PieChart

const CustomLabel = styled.text`
  font-size: ${smallText};
`

const CustomLegend = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  width: 80%;
  margin: 0 auto;
`

const CustomLegendItem = styled.li`
  font-size: ${smallText};
  margin-bottom: ${smallMargin};
  margin-left: ${bigMargin};

  ::before {
    content: '';
    background-color: ${(props) => props.color};
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: block;
    position: absolute;
    margin-left: -25px;
  }
`
