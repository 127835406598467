import React from 'react'
import ContentLoader from 'react-content-loader'

export const BarChartLoader = () => (
  <ContentLoader
    speed={4}
    width={360}
    height={252}
    viewBox='0 0 360 252'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    style={{ width: '100%' }}
  >
    <rect x='0' y='10' rx='5' ry='5' width='360' height='242' />
  </ContentLoader>
)
